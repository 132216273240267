import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { getUser, setUser as setLocalUser } from "../helpers";
import { baseUrl } from "../Contants";
import { logout, getCustomerCreateAccountStatus } from "../Actions";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const sessionTime = localStorage.getItem("sessionTime");
  const [user, setUser] = useState(getUser());
  const [loading, setLoading] = useState(false);
  const [isLogout, setIsLogout] = useState(
    sessionTime < new Date().getTime()
  );
  const [accessToken, setAccessToken] = useState("");
  const [authType, setAuthType] = useState("");
  const [canCreate, setCanCreate] = useState(false);
  const [delay, setDelay] = useState(36000000);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState();
  const [roleName, setRoleName] = useState();
  const [rolePrefix, setRolePrefix] = useState();

  const isUser = user && ["a","rw","ro"].includes(user.access_type_cd);

  useEffect(() => {
    if(authType === "local" || authType === "google") {
      setDelay(5000);
    };
    getCustomerCreateAccountStatus()
    .then((result) => {
      result.data.data[0].app_value > 0 ?
      setCanCreate(true) :
      setCanCreate(false);
    })
    async function getUser(url) {
      setLoading(true);
      const result = await axios(url);
      setUser(result.data);
      setLocalUser(result.data);
      setLoading(false);
      setIsLogout(false);
      setAccessToken("");
    }
    if (accessToken && authType === "google")
      getUser(`${baseUrl}/api/auth/google/user`);
    if (accessToken && authType === "local")
      getUser(`${baseUrl}/api/auth/local/user`);

      const interval = setInterval(() => { 
        if (sessionTime < new Date().getTime()) {
          setUser({});
          logout();
          setIsLogout(true);
          clearInterval(interval)
        }
      }, delay);
      
      return () => clearInterval(interval);

  }, [accessToken, authType, delay]);

  useEffect(() => {
    async function getRoles() {
      const result = await axios(`${baseUrl}/api/admin/roles`);
      setRoles(result.data.roleList);
      setRoleId(result.data.requestUserRole.role_id);
      setRoleName(result.data.requestUserRole.role_name);
      setRolePrefix(result.data.requestUserRole.prefix);
      sessionStorage.setItem('roleId', result.data.requestUserRole.role_id);
      sessionStorage.setItem('roles', JSON.stringify(result.data.roleList));
    }

    if (isUser) {
      getRoles();
    } else {
      // Clear out role information
      setRoles([]);
      setRoleId("");
      setRoleName("");
      setRolePrefix("");
      sessionStorage.removeItem('roleId');
      sessionStorage.removeItem('roles');
    }
  }, [isUser]);

  return (
    <AppContext.Provider
      value={{
        canCreate,
        user,
        setAccessToken,
        setUser,
        setLoading,
        loading,
        setIsLogout,
        isLogout,
        setAuthType,
        roles, 
        setRoleId, 
        setRoleName, 
        roleId, 
        roleName, 
        rolePrefix, 
        setRolePrefix
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
