import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../Context/AppContextProvide";


function AuthRoute({ component: Component, authed, ...rest }) {
  const { user, loading, isLogout, canCreate } = React.useContext(AppContext);
  const isUser = user && ["rw","ro"].includes(user.access_type_cd);
  const isAdmin = user && ["a"].includes(user.access_type_cd);

  const isAuthenticated = user && new Date().getTime() < user.will_expire_at;

  return (loading || !user.user_id) && !isLogout ? (
    <div>Loading...</div>
  ) : (
    <Route
      {...rest}
      render={(props) => {
        return (isAuthenticated === true && isUser) || isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/customer-home", state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

export default AuthRoute;
