import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../Context/AppContextProvide";

function CombinedAuthRoute({ component: Component, authed, ...rest }) {
  const { user } = React.useContext(AppContext);

  const isAuthenticated = new Date().getTime() < user.will_expire_at;
  const isUser = user.access_type_cd === "rw" || user.access_type_cd === "ro" ? true : false;
  const isAdmin = user.access_type_cd === "a" ? true : false;
  const isCustomer = user.access_type_cd === "c" ? true : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        return (isAuthenticated && isCustomer) || isAdmin || isUser === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/notfound", state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

export default CombinedAuthRoute;