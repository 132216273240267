import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
// url
import { baseUrl } from "../Contants";
import { AppContext } from "./AppContextProvide";
export const ProductContext = createContext();
export const ProductContextProvider = ({ children }) => {
  const { user, isLogout } = React.useContext(AppContext);
  const [product, setProduct] = useState({});
  const [path, setPath] = useState("");
  const [update, setUpdate] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [taxDisclaimer, setTaxDisclaimer] = useState("");
  const [progress, setProgress] = useState(true);

  const access_type_cd = user.access_type_cd
  let productUrl;

  if ( token && !isLogout && access_type_cd !== 'c' ){
    productUrl = `${baseUrl}/api/user/product/${path}`;
  }
  else {
    productUrl =`${baseUrl}/api/product/${path}`;
  }

  
  useEffect(() => {
    async function getProductDetails() {
      return axios.get(`${productUrl}`);
    }

    async function getProductDisclaimer() {
      const result = await axios.get(`${baseUrl}/api/admin/disclaimer/product`);
      setProductDisclaimer(result.data.data[0].product_disclaimer);
    };

    async function getTaxDisclaimer() {
      const result = await axios.get(`${baseUrl}/api/admin/taxdisclaimer`);
      setTaxDisclaimer(result.data.data[0].disclaimer);
    };

    if (path) {
      getTaxDisclaimer();
      getProductDisclaimer();
      getProductDetails()
      .then((result) => {
        setProduct(result.data.data[0]);
      })
      .catch((err) => {
        if (err.status === 401) {
          console.log(err)
          localStorage.clear();
          sessionStorage.clear();
          // window.location.reload();
          setProgress(false);
        } else {
          console.log(err)
        }
      })
      setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, update]);
  return (
    <ProductContext.Provider value={{ product, productDisclaimer, taxDisclaimer, progress, setPath, setUpdate }}>
      {children}
    </ProductContext.Provider>
  );
};