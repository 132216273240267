import React, { useContext, useEffect, useState, useRef } from "react";

// Dependencies
import { CompanyLogoContext } from "../../Context/CompanyLogoContextProvider";
import { uploadLogo as uploadLogoAction, 
  setLogoLink, 
  setManufacturerSwitchPosition, 
  getManufacturerSwitchPosition, 
  setRoomCalcSwitchPosition,
  getRoomCalcSwitchPosition,
  setLocationSwitchPosition,
  getLocationSwitchPosition,
  setCustomerCreateAccountsSwitchPos,
  getCustomerCreateAccountStatus,
  getProdCheckoutSwitchPosition,
  setProdCheckoutSwitchPosition,
  getProdFavSwitchPosition,
  setProdFavSwitchPosition,
  getMaterialClassCodes, 
  getCodeTranslations,
  getUom,
  getQrPrintConfig, 
  setNotificationsSwitchPos,
  getNotificationsSwitchPos,
  setRoomvoToggle,
  getRoomvoToggle
} from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";
import { getUser } from "../../helpers/index";
import MaterialClassSettings from "./MaterialClassSetting";
import UomSettings from "./UomSettings";
import QrPrintSetting from "./QrPrintSettings";

// MUI
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ToolTip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import ExpandMoreIcon from"@material-ui/icons/ExpandMore";
import { Button, 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Divider, 
  Dialog,
  DialogTitle, 
  List,
  ListItem,
  ListItemText} from "@material-ui/core";

// Styles
import "../styles/Admin.css";

const LogoLinkUpdate = () => {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const { logoLink, setUpdate, companyLogo} = useContext(CompanyLogoContext);
  const [companyLink, setCompanyLink] = useState("www.yoursite.com");
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);
  const [switchState, setSwitchState] = useState(false);
  const [roomvoSwitchState, setRoomvoSwitchState] = useState(false);
  const [roomCalcSwitchState, setRoomCalcSwitchState] = useState(true);
  const [locationSwitchState, setLocationSwitchState] = useState(true);
  const [userRegSwitchState, setUserRegSwitchState] = useState(false);
  const [notificationsSwitchState, setNotificationsSwitchState] = useState(false);
  const [userAccessType] = useState(getUser().access_type_cd);
  const [username] = useState(getUser().user_name);
  const [prodCheckoutSwitchState, setProdCheckoutSwitchState] = useState(false);
  const [prodFavSwitchState, setProdFavSwitchState] = useState(false);
  const [open, setOpen] = useState(false);
  const [matClassCdArray, setMatClassCdArray] = useState([]);
  const [codeTranslationArray, setCodeTranslationArray] = useState([]);
  const [uomArray, setUomArray] = useState([]);
  const [dialogSelect, setDialogSelect] = useState('');
  const [margins, setMargins] = useState([]);

  useEffect(() => {
    getQrPrintConfig()
    .then((result) => {
      setMargins(result.data.data[0].config);
    })
    .catch((err) => {
      console.log(err);
    });

    getUom()
    .then((result) => {
      setUomArray(result.data.data);
    })
    .catch((err) => {
      console.log(err);
    });

    getCodeTranslations()
    .then((result) => {
        setCodeTranslationArray(result.data.data);
    });

    getMaterialClassCodes()
    .then((result) => {
        if (result.data.data.length) {
            setMatClassCdArray(result.data.data);
          }
    })
    .catch((err) => {
        console.log(err);
    });

    getProdCheckoutSwitchPosition()
    .then((result)=> {
      if(Number(result.data.data[0].app_value) === 1) {
        setProdCheckoutSwitchState(true);
      } else {
        setProdCheckoutSwitchPosition(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });

    getProdFavSwitchPosition()
    .then((result)=> {
      if(Number(result.data.data[0].app_value) === 1) {
        setProdFavSwitchState(true);
      } else {
        setProdFavSwitchPosition(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });

    getCustomerCreateAccountStatus()
    .then((result) => {
      if(Number(result.data.data[0].app_value) === 1) {
        setUserRegSwitchState(true);
      } else {
        setUserRegSwitchState(false);
      }
    })

    getManufacturerSwitchPosition()
    .then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setSwitchState(true);
      } else {
        setSwitchState(false);
      }
    })

    getRoomCalcSwitchPosition()
    .then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setRoomCalcSwitchState(true);
      } else {
        setRoomCalcSwitchState(false);
      }
    })

    getLocationSwitchPosition()
    .then((result) => {
      if (parseInt(result.data.data[0].active) === 1) {
        setLocationSwitchState(true);
      } else {
        setLocationSwitchState(false);
      }
    })

    getNotificationsSwitchPos()
    .then((result) => {
      if (parseInt(result.data.sample_reminder) === 1) {
        setNotificationsSwitchState(true);
      } else {
        setNotificationsSwitchState(false);
      }
    })
    
    if (logoLink.length) {
        setCompanyLink(logoLink);
    }
  }, [logoLink]);

  useEffect(() => {
    getRoomvoToggle().then((result) => {
      const toggle = parseInt(result.data) === 1;
      setRoomvoSwitchState(toggle);
    }).catch((err) => {
      setRoomvoSwitchState(false);
    });
  }, [])

  const handleUpdateLogoLink = (e) => {
    setCompanyLink(e.target.value);
  };

  const handleLogoLinkUpdate = () => {
      setLogoLink(companyLink)
        .then(() => {
          setMessage("Successfully updated logo link");
          setMessageType("success");
          handleAlertOpen();
          window.location.reload();
        })
        .catch(() => {
          setMessage("Error updating logo link");
          setMessageType("error");
          handleAlertOpen();
        });
  };

  const handleFileUpload = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    }
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const uploadLogo = () => {
    let formData = new FormData();
    formData.append("image", file);
    uploadLogoAction(formData)
      .then((result) => {
        setMessage("Logo Uploaded Successfully");
        setMessageType("success");
        handleAlertOpen();
        setUpdate(true);
        window.location.reload();
      })
      .catch((err) => {
        setMessage("Failed to upload company logo");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  // const handleCancel = () => {
  //   window.location.reload()
  // };

  const handleChange = (event) => {
    setSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setManufacturerSwitchPosition("1")
    }
    if (Number(event.target.checked) === 0) {
      setManufacturerSwitchPosition("0");
    }
  };

  const handleRoomvoChange = (event) => {
    setRoomvoSwitchState(event.target.checked);

    const toggle = Number(event.target.checked) === 1 ? "1" : "0";
    setRoomvoToggle(toggle);
  };

  const handleRoomCalcChange = (event) => {
    setRoomCalcSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setRoomCalcSwitchPosition("1")
    }
    if (Number(event.target.checked) === 0) {
      setRoomCalcSwitchPosition("0");
    }
  };

  const handleLocationChange = (event) => {
    setLocationSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setLocationSwitchPosition("1")
    }
    if (Number(event.target.checked) === 0) {
      setLocationSwitchPosition("0");
    }
  };
  
  const handleProdCheckoutChange = (event) => {
    setProdCheckoutSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setProdCheckoutSwitchPosition("1")
    }
    if (Number(event.target.checked) === 0) {
      setProdCheckoutSwitchPosition("0");
    }
  };

  const handleProdFavChange = (event) => {
    setProdFavSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setProdFavSwitchPosition("1")
    }
    if (Number(event.target.checked) === 0) {
      setProdFavSwitchPosition("0");
    }
  };

  const handleUserRegChange = (event) => {
    setUserRegSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setCustomerCreateAccountsSwitchPos("1")
    }
    if (Number(event.target.checked) === 0) {
      setCustomerCreateAccountsSwitchPos("0");
    }
  };

  const handleNotificationsChange = (event) => {
    setNotificationsSwitchState(event.target.checked)
    if (Number(event.target.checked) === 1) {
      setNotificationsSwitchPos("1")
    }
    if (Number(event.target.checked) === 0) {
      setNotificationsSwitchPos("0");
    }
  };
  
  const renderImg = () => {
    return (
        file === undefined ?
        <img className="company-logo" src={companyLogo} alt="LOGO"></img> :  
        <img className="company-logo" src={file? URL.createObjectURL(file) : null} alt={file? file.name : null}/>
    )};

    const renderUploadLogo = () => {
        return (
          <div>
            <div className="mnd__header">
              <Typography
                className="header-text"
                variant="h4">
                Company Logo
              </Typography>
            </div>
            <div style={{width: "90%", margin: "auto"}}>
              <input
                className="admin__bulletin-image-uploader"
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileUpload}
                accept="image/x-png"
              ></input>
              <h4 className="upload-logo-text">Image Preview:</h4>
              {renderImg()}
              <div style={{textAlign: "right"}}>
                <Button
                  className="admin__search-logo-btn"
                  variant="contained"
                  onClick={handleUpload}
                  autoFocus
                  disableRipple
                >
                  Browse Image
                </Button>
                <Button
                  className="admin__upload-logo-btn"
                  variant="contained"
                  onClick={uploadLogo}
                  autoFocus
                  disableRipple
                >
                  Submit
                </Button>
              </div>
              
            </div>
          </div>
        )};  

    const renderLogoLink = () => {
          return (
            <div>
              <div className="mnd__header">
                <Typography
                  className="header-text"
                  variant="h4"
                >
                  Logo Link
                </Typography>
              </div>
              <div style={{textAlign: "right", width: "90%", margin: "auto"}}>
                <TextField
                  className="mnd__text-area"
                  variant="filled"
                  name="logolink"
                  label="Eg. http://www.yoursite.com"
                  placeholder="Enter Company Link"
                  value={companyLink}
                  onChange={handleUpdateLogoLink}
                />
                <Button
                  className="admin__logo-link-btn"
                  variant="contained"
                  onClick={handleLogoLinkUpdate}
                  autoFocus
                  disableRipple
                >
                  Submit
                </Button>
              </div>
            </div>
          )};  

    const renderViewRoomvo = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Off
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">Select to show roomvo on product page</Typography>}>
              <Switch
                checked={roomvoSwitchState}
                onChange={handleRoomvoChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              On
            </Typography>
          </Grid>
        </Grid>
      )
    };

    const renderViewManufacturer = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Hide Manufacturer
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">Select to show manufacturer on product page</Typography>}>
              <Switch
                checked={switchState}
                onChange={handleChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Show Manufacturer
            </Typography>
          </Grid>
        </Grid>
      )
    };

    const renderViewRoomCalculator = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Hide Room Calculator
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">Select to show room size calculator on product page</Typography>}>
              <Switch
                checked={roomCalcSwitchState}
                onChange={handleRoomCalcChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
            Show Room calculator
            </Typography>
          </Grid>
        </Grid>
      )
    };


    const renderViewLocation = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Hide Location
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">Select to show location to customer</Typography>}>
              <Switch
                checked={locationSwitchState}
                onChange={handleLocationChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Show Location
            </Typography>
          </Grid>
        </Grid>
      )
    };
  
  const renderProductCheckout = () => {
    return ( 
      <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
        <Grid item xs={4}>
          <Typography variant="button" style={{display: "block"}}>
            Prohibit Checkout
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip title={<Typography variant="subtitle1">Select to allow or prohibit product checkout</Typography>}>
            <Switch
              checked={prodCheckoutSwitchState}
              onChange={handleProdCheckoutChange}
              color="primary"
              size="medium"
            >
            </Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{display: "block"}}>
            Allow Checkout
          </Typography>
        </Grid>
      </Grid>
    )
  };

  const renderProductFavorite = () => {
    return ( 
      <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
        <Grid item xs={4}>
          <Typography variant="button" style={{display: "block"}}>
            Prohibit Favorite
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ToolTip title={<Typography variant="subtitle1">Select to allow or prohibit product favorites</Typography>}>
            <Switch
              checked={prodFavSwitchState}
              onChange={handleProdFavChange}
              color="primary"
              size="medium"
            >
            </Switch>
          </ToolTip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button" style={{display: "block"}}>
            Allow Favorite
          </Typography>
        </Grid>
      </Grid>
    )
  };

    const renderAllowRegistration = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Deny
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">User Registration Status</Typography>}>
              <Switch
                checked={userRegSwitchState}
                onChange={handleUserRegChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Allow
            </Typography>
          </Grid>
        </Grid>
      )
    };

    const renderAllowNotifications = () => {
      return ( 
        <Grid container spacing={0} style={{margin: "30px auto 40px", textAlign: "center"}} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Prohibit Notifications
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <ToolTip title={<Typography variant="subtitle1">Select to allow users to receive notifications</Typography>}>
              <Switch
                checked={notificationsSwitchState}
                onChange={handleNotificationsChange}
                color="primary"
                size="medium"
              >
              </Switch>
            </ToolTip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="button" style={{display: "block"}}>
              Allow Notifications
            </Typography>
          </Grid>
        </Grid>
      )
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleMaterialClassOpen = () => {
      setDialogSelect('materialClass')
      setOpen(true);
    };

    const handleUomOpen = () => {
      setDialogSelect("uom");
      setOpen(true);
    };

    const handleQrMarginOpen = () => {
      setDialogSelect("qr code margins");
      setOpen(true);
    };

    const renderDialog = () => {
      return (
      <Dialog onClose={handleClose} open={open} maxWidth="lg" style={{display:"flex", justifyContent:"center"}}>
          <DialogTitle className="files-by-partner-header">
            { dialogSelect === "materialClass" ?
              "Material Class Codes" :
              dialogSelect ===  "uom" ? 
              "Units of Measure" :
              "Qr Print Margins"
              }
              <Button variant="outlined"
              style={{color:"#FF682C", marginLeft:20, fontSize:20, float:"right", border:"1px solid #DCDCDC"}}
              onClick={handleClose}>
              X
              </Button>
          </DialogTitle>
          { dialogSelect === "materialClass" ?
          <MaterialClassSettings matClassCdArray={matClassCdArray} codeTranslationArray={codeTranslationArray} /> :
          dialogSelect === "uom" ?
          <UomSettings uomArray={uomArray} codeTranslationArray={codeTranslationArray} />  :
          <QrPrintSetting margins={margins} /> }
      </Dialog>
      )};

  return (
    <div className="settings__container">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography
                variant="h4"
                className="header-text"
              >
                Roomvo Settings
            </Typography>
            <Grid container spacing={1} style={{textAlign: "center"}}>
              <Grid item xs={12} sm={12} md={6} lg={5} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                <Typography variant="h4" className="admin__product-settings" style={{textAlign: "center"}}>
                  Roomvo Visualizer
                </Typography>
                {renderViewRoomvo()}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{textAlign: "left", margin: "10px auto", padding: "0px 20px"}}>
                <Typography variant="h4" className="admin__product-settings" style={{textAlign: "left"}}>
                  Roomvo Instructions
                </Typography>
                <Typography style={{margin: "10px 0px"}}>
                  Please ensure the following steps are completed before turning on Roomvo.
                </Typography>
                <List>
                  <ListItem >
                    <ListItemText>1. If you have not signed up with roomvo already, please go to the <a href="https://www.roomvo.com/share/ShowroomPricing" target="_blank">signup page</a>.</ListItemText>
                  </ListItem>
                  <ListItem >
                    <ListItemText>2. Make sure your Showroom Pricing URL is added to your Roomvo account.</ListItemText>
                  </ListItem>
                </List>
                <Typography><b>Please note: Roomvo links will be on all product pages and is based on the product SKU. If a SKU is not in Roomvo's system then a random product will be shown instead. <u>This is a limitation with Roomvo and not Showroom Pricing.</u></b></Typography>
              </Grid>
            </Grid>
            <Accordion style={{marginTop: "20px"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Roomvo Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can toggle your Roomvo integration to decide if it should show on the product pages or not.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            {renderUploadLogo()}
            <Accordion style={{marginTop: "40px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography variant="h5">Company Logo Upload</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Use this to upload your company logo or an image of your choice. (PNG files only)
                  </Typography>
                </AccordionDetails>
              </Accordion>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            {renderLogoLink()}
            <Accordion style={{marginTop: "40px"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Logo Link</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can set your company logo as a link to wherever you like.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography
              variant="h4"
              className="header-text"
            >
              Product Scan Page Settings
            </Typography>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Manufacturer
              </Typography>
              {renderViewManufacturer()} 
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Room Calculator
              </Typography>
              {renderViewRoomCalculator()}
            </div>  
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Location
              </Typography>
              {renderViewLocation()}
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Checkout
              </Typography>
              {renderProductCheckout()} 
            </div>
            <div className="admin__settings-table-cell-container">
              <Typography variant="h4" className="admin__product-settings">
                Favorite
              </Typography>
              {renderProductFavorite()}  
            </div>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Product Page Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense={true}>
                  <ListItem>
                    <ListItemText 
                      primary="Manufacturer"
                      secondary="Selector to show manufacturer on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Room Calculator"
                      secondary="Selector to show room size calculator on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Location"
                      secondary="Selector to show location on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Checkout"
                      secondary="Selector to show product checkout on product page."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Favorite"
                      secondary="Selector to show product favorite on product page."
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5}>
            <Typography
              variant="h4"
              className="header-text"
            >
              New User Registration
            </Typography>
            
            <Typography variant="h4" className="admin__product-settings">
              User Registration
            </Typography>
              {renderAllowRegistration()} 
              <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">New User Registration</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use this switch to allow customers to create an account.
                </Typography>
                
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        { 
          /**
           * Adding this to hide the site notification setting from everyone besides the hidden user for now. 
           * That way nothing gets turned on by accident and gives us the option to do more testing on specific sites
           */
          getUser().is_hidden == 1 ? (
            <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
              <Paper elevation={5}>
                <Typography
                  variant="h4"
                  className="header-text"
                >
                  User Notifications
                </Typography>
                <Typography variant="h4" className="admin__product-settings">
                  Notifications
                </Typography>
                  {renderAllowNotifications()} 
                  <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography variant="h5">User Notifications</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Use this switch to allow users to receive notifications.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
          ) : undefined 
        }


        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5} style={{textAlign: "center"}}>
            <Typography
                variant="h4"
                className="header-text"
              >
                Import Settings
            </Typography>
            <Typography variant="h4" className="admin__product-settings">
              Material Class/UOM
            </Typography>
              <Button variant="contained" onClick={handleMaterialClassOpen} style={{display: "inline-block", color:"#FF682C", margin: "30px 20px 0px", fontSize:20, border:"1px solid #DCDCDC"}}>
                View/Edit Material Classes
              </Button>
              <Button variant="contained" onClick={handleUomOpen} style={{display: "inline-block", color:"#FF682C", margin: "30px 20px 0px", fontSize:20, border:"1px solid #DCDCDC"}}>
                View/Edit UOM
              </Button>
            <Accordion style={{marginTop: "40px"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Import Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can add new material classes, material class codes, and UOM types.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} className="admin__grid-item">
          <Paper elevation={5} style={{textAlign: "center"}}>
            <Typography
                variant="h4"
                className="header-text"
              >
                QR Print Settings
            </Typography>
            <Typography variant="h4" className="admin__product-settings">
              QR Code Print Margins
            </Typography>
            <Button variant="contained" onClick={handleQrMarginOpen} style={{display: "inline-block", color:"#FF682C", margin: "30px 20px 40px", fontSize:20, border:"1px solid #DCDCDC"}}>
              Edit Print Margins
            </Button>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">QR Print Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Here you can adjust the print margins of the QR codes.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
      {renderDialog()}
    </div>
  );
};

export default LogoLinkUpdate;